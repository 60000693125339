<template>
  <div style="background: #f6f6f6; position: relative;">
    <van-nav-bar
      title="任务详情"
      left-arrow
      @click-left="goBack"
      fixed
      placeholder
    />

    <div class="container">
      <div>
        <van-row style="height: 78px;">
          <van-col span="5">
            <div class="logo">
              <van-image
                width="58"
                height="58"
                :src="detail.taskLogoUrl"
              />
            </div>
          </van-col>
          <van-col span="11">
            <div class="taskName">
              <p style="font-weight: bold;">{{ detail.taskName }}</p>
              <p style="font-size: 14px; color: #999;">{{ detail.taskType }}</p>
            </div>
          </van-col>
          <van-col span="8"><span class="gold" style="font-size: 17px; color: #FF5200;">+{{ detail.profit }}</span></van-col>
        </van-row>
      </div>
      <van-row style="font-size: 12px; color: #999; margin-bottom: 15px;">
        <van-col :span="12">剩余份数：{{ detail.surplusNum }}/{{ detail.launchNum }}</van-col>
        <van-col :span="12">截止日期：{{ endTime[1] }}月{{ endTime[2] }}日</van-col>
      </van-row>
      <van-row style="font-size: 12px;">
        <van-checkbox-group  icon-size="15px" @change="checkbox" checked-color="#62d192" v-model="result" direction="horizontal">
          <van-checkbox name="a"><span style="color: #62d192;">无恶意收费</span></van-checkbox>
          <van-checkbox name="a"><span style="color: #62d192;">人工检测无毒</span></van-checkbox>
          <van-checkbox name="a"><span style="color: #62d192;">加密防泄漏</span></van-checkbox>
        </van-checkbox-group>
      </van-row>
    </div>

    <div class="container">
      <p style="font-size: 18px; color: #333; font-weight: bold; margin: 6px 0;">任务说明</p>
      <p style="font-size: 15px; color: #999; margin: 6px 0;">{{ detail.taskNote }}</p>
    </div>

    <div class="container">
      <p style="font-size: 18px; color: #333; font-weight: bold; margin-bottom: 10px;">任务步骤</p>
      <div>
        <div v-for="(item, index) in detail.stepVOList" :key="index" style="margin-bottom: 15px;">
          <p style="font-size: 14px; color: #333; margin-bottom: 10px;">{{ index + 1 }}.{{ item.stepNote }}</p>
          <div class="checkImg">
            <div>点击可查看大图</div>
          </div>
          <van-image
            @click="img(item.stepImgUrl)"
            width="80"
            height="80"
            :src="item.stepImgUrl[0]"
          />
        </div>
      </div>
    </div>

    <div class="container last-container">
      <p style="font-size: 18px; color: #333; font-weight: bold; margin-bottom: 10px;">提交信息</p>
      <div class="container-step">
        <div style="display: flex; justify-content: center; align-items: center;">1</div>
        <span style="sont-size: 15px; color: #333;">提交信息</span>
      </div>
      <div class="commit">
        <van-form ref="form">
          <div style="margin-bottom: 15px;" v-if="detail.collectInfo.split(',').includes('1')">
            <van-cell-group>
              <van-field 
                v-model="registerCode"
                placeholder="请输入用户名"
                :rules="[{ required: true, message: '' }]"
              />
            </van-cell-group>
          </div>
          <div style="margin-bottom: 15px;" v-if="detail.collectInfo.split(',').includes('2')">
            <van-cell-group>
              <van-field 
                v-model="registerId" 
                placeholder="请输入账户ID"
                :rules="[{ required: true, message: '' }]"
              />
            </van-cell-group>
          </div>
          <div style="margin-bottom: 15px;" v-if="detail.collectInfo.split(',').includes('3')">
            <van-cell-group>
              <van-field 
                v-model="registerMobile" 
                placeholder="请输入手机号"
                :rules="[
                  { required: true, message: '' },
                  { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
                ]"
              />
            </van-cell-group>
          </div>
        </van-form>
      </div>
      <div class="container-step">
        <div style="display: flex; justify-content: center; align-items: center;">2</div>
        <span style="sont-size: 15px; color: #333;">上传截图</span>
      </div>
      <div>
        <van-uploader
          v-model="fileList" 
          :after-read="afterRead(fileList)" 
          :before-delete="deleteImg"/>
      </div>
    </div>

    <div class="sell">
      <van-goods-action>
        <van-goods-action-button text="下载app" @click="downLoad(downloadUrl)"/>
        <van-goods-action-button text="提交任务" @click="submitTask"/>
      </van-goods-action>
    </div>

  </div>
</template>

<script>
import { ImagePreview, Toast } from 'vant';
import { fetchTask, submitTask, upload } from '@/api/printscreen'
  export default {
    data () {
      return {
        registerMobile: '',
        result: ['a'],
        registerId: '',
        registerCode: '',
        fileList: [],
        detail: {},
        endTime: [],
        downloadUrl: '',
        images: [],
        phone: '',
      }
    },
    created () {
      this.$store.commit('setSession', this.$route.query.session)
      this.fetchTask()
      this.phoneType()
    },
    methods: {
      phoneType () {
        let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          this.phone = 1
        }
        if (isIOS) {
          this.phone = 2
        }
      },

      downLoad (downloadUrl) {
        try {
          window.webkit.messageHandlers.openBrowser.postMessage(downloadUrl)
        }
        catch {
          window.android.openBrowser(downloadUrl)
        }
      },

      fetchTask () {
        fetchTask(this.$route.query.id).then(res => { // 1465933385530793986 06f365c0-772f-4e33-89f1-dad2df0e9a3c
          if (res.data.code === 0) {
            this.detail = res.data.data
            this.endTime = res.data.data.useEndTime.split(' ')[0].split('-')
            if (res.data.data.taskPlatform == 1 || res.data.data.taskPlatform == 2) {
              this.downloadUrl = res.data.data.downloadUrl
            } else if (res.data.data.taskPlatform == 3) {
              if (this.phone == 1) {
                this.downloadUrl = res.data.data.androidUrl
              } else if (this.phone == 2) {
                this.downloadUrl = res.data.data.iosUrl
              }
            }
          }
        })
      },

      img (img) {
        ImagePreview({
          images: img,
          closeable: true,
        })
      },

      afterRead() {
        return (file) => {
          file.status = 'uploading'
          file.message = '上传中'
          let formData = new window.FormData()
          formData.append('file', file.file)
          formData.append('fileType', 'imgage/png'),
          formData.append('dir', 'material')
          upload(formData).then(res => {
            if (res.data.code === 0) {
              file.status = ''
              file.message = ''
              this.images.push(res.data.data)
            }
          }).catch(() => {
            file.status = 'failed',
            file.message = '上传失败'
          })
        }
      },

      deleteImg (file, detail) {
        return new Promise((resolve, reject) => {
          this.$dialog.confirm({
            message: '确认删除截图？'
          }).then(() => {
            this.images.splice(detail.index, 1)
            this.$toast.success('删除成功')
            resolve()
          }).catch(error => {
            this.$toast.fail('已取消')
            reject(error)
          })
        })
      },

      submitTask () {
        this.$refs.form.validate().then(() => {
          if (!this.images.length) {
            this.$toast.fail('请上传截图')
            return
          }
          let params = {
            taskId: this.$route.query.id,
            registerCode: this.registerCode,
            registerId: this.registerId,
            registerMobile: this.registerMobile,
            images: this.images,
          }
          submitTask(params).then(res =>{
            if (res.data.code === 0) {
              Toast.success('提交成功')
              setTimeout(() =>{
                this.goBack()
              }, 1500)
            }
          })
        }).catch(() => {
            this.$toast.fail('请填写提交信息')
        })
      },

      goBack () {
        let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isIOS) {
          window.webkit.messageHandlers.close.postMessage('up');   
        }
        if (isAndroid) {
          window.android.close()
        } 
      },

      checkbox () {
        this.result = ['a']
      }
    }
  }
</script>

<style lang="less" scoped>
.container {
  width: 96%;
  background: #fff;
  border-radius: 16px;
  margin: 10px auto;
  box-sizing: border-box;
  padding: 17px 0 17px 3%;
}
.container-step {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  div {
    background: #FF7200;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 12px;
    color: #fff;
    span {
      line-height: 24px;
    }
  }
}
.logo {
  /deep/ .van-image__img {
    border-radius: 16px;
    box-shadow: 0 0 5px 0 rgb(190, 190, 190);
  }
  height: 77px;
  display: flex;
  align-items: center;
}
.taskName {
  display: flex;
  height: 77px;
  flex-direction: column;
  justify-content: center;
}
.gold {
  display: flex;
  height: 66px;
  align-items: flex-end;
  margin-right: 4%;
  justify-content: flex-end;
}
.checkImg {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  div {
    background: #EEEEEE;
    border-radius: 10px;
    font-size: 12px;
    color: #999;
    padding: 2px 3%;
  }
}
.commit {
  .van-cell-group {
    width: 60%;
    .van-cell {
      background: #eee;
      border-radius: 6px;
    }
  }
}
.last-container {
  padding-bottom: 100px;
}
.sell {
  width: 100%;
  /deep/ .van-goods-action {
    height: 50px;
  }
  .van-goods-action-button--first {
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 20px;
    background: linear-gradient(to right, #FF7200, #FF3C00 );
    color: white;
  }
  .van-goods-action-button--last {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    background: linear-gradient(to right, #FF7200, #FF3C00 );
    color: white;
  }
}
</style>