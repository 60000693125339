import { fetchGet, fetchPost, fetchPut } from '@/router/https'

const fetchTask = (id) => fetchGet('/createrapp/maker/advertside/task/' + id)

const upload = (param) => fetchPost('/mallapp/feedbackrelease/uploadFile', param)

const submitTask = (params) => fetchPut('/createrapp/maker/advertside/task/commit', params)

export {
  fetchTask,
  submitTask,
  upload
}